import acceptCookies from './accept-cookies.json'
import admin from './admin.json'
import bigFilter from './big-filter.json'
import comparisonPage from './comparison-page.json'
import contacts from './contacts.json'
import discountPopup from './discount-popup.json'
import errors from './errors.json'
import footer from './footer.json'
import header from './header.json'
import homePage from './home-page.json'
import infoPopup from './info-popup.json'
import likesPopup from './likes-popup.json'
import loginModal from './login-modal.json'
import map from './map.json'
import mobileModal from './mobile-modal.json'
import notFound from './not-found.json'
import npsModal from './nps-modal.json'
import offerConfiguration from './offer-configuration.json'
import reviewPopup from './review-popup.json'
import roomPage from './room-page.json'
import selects from './selects.json'
import tooltips from './tooltips.json'
import translation from './translation.json'
import tutorial from './tutorial.json'

export const en = {
	translation: translation,
	'discount-popup': discountPopup,
	'home-page': homePage,
	'room-page': roomPage,
	tutorial: tutorial,
	'comparison-page': comparisonPage,
	header: header,
	contacts: contacts,
	footer: footer,
	tooltips: tooltips,
	map: map,
	'not-found': notFound,
	'login-modal': loginModal,
	'nps-modal': npsModal,
	'review-popup': reviewPopup,
	'info-popup': infoPopup,
	'likes-popup': likesPopup,
	'accept-cookies': acceptCookies,
	'big-filter': bigFilter,
	'mobile-modal': mobileModal,
	errors: errors,
	'offer-configuration': offerConfiguration,
	selects: selects,
	admin: admin
} as const
