import { createBrowserRouter } from 'react-router-dom'

import { NotFoundPage } from './pages/NotFoundPage'

const developmentRoutes = [
	{
		path: 'demo',
		ErrorBoundary: NotFoundPage,
		children: [
			{
				index: true,
				lazy: async () => {
					const { DemoPage } = await import('./pages/DemoPage')
					return { Component: DemoPage }
				}
			},
			{
				path: 'buttons',
				lazy: async () => {
					const { ButtonDemoPages } = await import(
						'./pages/DemoPage/pages/ButtonDemoPages'
					)

					return { Component: ButtonDemoPages }
				}
			},
			{
				path: 'feature-is-not-implemented',
				lazy: async () => {
					const { FeatureIsNotImplementedPage } = await import(
						'./pages/DemoPage/pages/FeatureIsNotImplementedPage'
					)
					return { Component: FeatureIsNotImplementedPage }
				}
			},
			{
				path: 'toasts',
				lazy: async () => {
					const { ToastDemoPage } = await import(
						'./pages/DemoPage/pages/ToastDemoPage'
					)
					return { Component: ToastDemoPage }
				}
			}
		]
	}
]

/**
 * Routes:
 *
 * Base:
 * / - Home page
 * /room-offer - Room offer page
 * /comparison - Comparison table page
 * /admin - Admin page
 *
 * Development:
 * /demo - Demo page with links to all demo's
 * /demo/buttons - Button demo page
 * /demo/feature-is-not-implemented - Feature is not implemented tooltip demo page
 * /demo/toasts - Toast demo page
 */
export const router = createBrowserRouter([
	{
		path: '/',
		lazy: async () => {
			const { App } = await import('./components/App/App.tsx')

			return { Component: App }
		},
		ErrorBoundary: NotFoundPage,
		children: [
			{
				index: true,
				lazy: async () => {
					const { HomePage } = await import('@wwt/pages/home')

					return { Component: HomePage }
				}
			},
			{
				path: 'room-offer',
				lazy: async () => {
					const { RoomOfferPage } = await import('@wwt/pages/room-offer')

					return { Component: RoomOfferPage }
				}
			},
			{
				path: 'comparison',
				lazy: async () => {
					const { ComparisonPage } = await import('@wwt/pages/comparison')

					return { Component: ComparisonPage }
				}
			},
			{
				path: 'admin',
				lazy: async () => {
					const { AdminPage } = await import('@wwt/pages/admin')

					return { Component: AdminPage }
				}
			},
			...(import.meta.env.DEV ? developmentRoutes : [])
		]
	}
])
