import { inputAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(parts.keys)

const baseStyle = defineStyle({
	field: {
		borderWidth: '0.0625rem',
		borderRadius: '1rem',
		color: 'gray.500',
		borderColor: 'gray.200',
		bg: 'white',
		/**
		 * Bug with textStyles Chakra UI
		 *
		 * @readonly Font is body-text-6
		 *
		 * https://github.com/chakra-ui/chakra-ui/issues/3884#issuecomment-905591584
		 */
		fontWeight: 400, // Regular
		fontSize: 'md', // 1rem

		_hover: {
			bg: 'gray.100',
			cursor: 'pointer'
		},
		_placeholder: {
			color: 'gray.500',

			/**
			 * Fix problem with show correct color in Firefox
			 *
			 * Check https://stackoverflow.com/a/19621642
			 *
			 * @author Ivan Shchedrovskyi
			 */
			opacity: 1
		},

		/**
		 * Watch variantDefault comment
		 */
		_focusVisible: {},
		_disabled: {
			color: 'gray.200',
			bg: 'gray.100'
		}
	}
})

/**
 * Why we can't use baseStyles for focusVisible?
 *
 * This is related to the standard variants of Chakra UI. By default, we use the outline variant.
 * This variant includes standard focusVisible styles that CANNOT be removed in base.
 * Therefore, for proper component styling, we will use a custom variant.
 *
 * Chakra UI github, input outline style _focusVisible - https://github.com/chakra-ui/chakra-ui/blob/88706a0648832512b38e6b18bf9192d6bfb58bf6/packages/theme/src/components/input.ts#L117
 */
const variantDefault = defineStyle({
	field: {
		_focusVisible: {
			borderColor: 'brand.300',
			color: 'gray.500'
		}
	}
})

const variantWithShadow = defineStyle({
	field: {
		_focusVisible: {
			...variantDefault.field._focusVisible,
			'--shadow-color': 'colors.brand.300',
			boxShadow:
				'0 0.875rem 1.5rem -0.5625rem color-mix(in srgb, var(--shadow-color) 20%, transparent)'
		}
	}
})

const variants = {
	default: variantDefault,
	defaultWithShadow: variantWithShadow
}

const sizes = {
	sm: definePartsStyle({
		field: {
			height: '2.5rem' // 2.5rem
		}
	}),
	md: definePartsStyle({
		field: {
			height: '3rem' // 3rem
		}
	}),
	lg: definePartsStyle({
		field: {
			height: '4rem', // 4rem,
			textStyle: 'body-text-6'
		}
	})
}

export const inputTheme = defineMultiStyleConfig({
	baseStyle,
	variants,
	sizes,
	defaultProps: {
		variant: 'default'
	}
})
