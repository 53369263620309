import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
	overlay: {
		backdropFilter: 'blur(0.78125rem)' // 12.5px
	},

	/**
	 * Use careful `alignItems` in dialogContainer
	 * It can broke scroll
	 */
	dialogContainer: {
		padding: { base: '0', md: '3.25rem', xl: '5rem' },
		maxH: '100dvh'
	},

	dialog: {
		borderRadius: 'md',
		borderBottomRadius: { base: '0', md: 'md' },
		margin: { base: '2rem 0 0 0', md: '0' },
		minH: { base: '100%', md: 'unset' }
	},

	closeButton: {
		top: '1.1875rem',
		right: '1.4375rem'
	},

	header: {
		fontSize: '2.5rem',
		fontWeight: 'medium',
		textAlign: 'center',
		pb: 5,
		borderBottom: '0.0625rem solid #AAAAAA'
	},

	footer: {
		pos: { base: 'fixed', md: 'relative' },
		left: 0,
		bottom: 0,
		h: { base: '10.5rem', md: '4rem' },
		w: '100%',
		maxW: '100vw',
		p: { base: '1.25rem', md: '0' },
		justifyContent: { base: 'flex-start', md: 'space-between' },
		gap: { base: '1.5rem', md: 'unset' },
		alignItems: { base: 'flex-start', md: 'center' },
		flexDirection: { base: 'column', md: 'row' },
		bg: 'white',
		borderTopRadius: { base: 'sm', md: 'unset' },
		boxShadow: { base: '0 0.125rem 0.75rem 0 #00000014', md: 'unset' },
		borderBottomRadius: { base: '0', md: 'md' },
		zIndex: 1
	},

	body: {
		px: 0
	}
})

const sizes = {
	xl: {
		dialog: {
			maxW: '80rem'
		}
	}
}

export const modalTheme = defineMultiStyleConfig({
	baseStyle,
	sizes,
	defaultProps: {
		size: 'xl'
	}
})
